import { Trans } from "@lingui/macro";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { StyledConnectButton, Web3StatusConnectWrapper } from "components/Web3Status";
import { Link } from "react-router-dom";
import { isVerified as isWalletVerified} from '@haven1network/h1-fee'
import { useEffect, useState } from 'react'


export function isVerified(address: string | undefined): boolean {

    const [result, setResult] = useState(false)
    useEffect(() => {
        if(address !== undefined){
            async function fetchWallet() {
                setResult(await isWalletVerified(
                    address!,
                    process.env.REACT_APP_ACCOUNT_MANAGER_ADDRESS! as string,
                    process.env.REACT_APP_RPC_URL! as string,
                    Number.parseInt(process.env.REACT_APP_CHAIN_ID!),
                    process.env.REACT_APP_CHAIN_NAME! as string
                ));
            }
    
            fetchWallet();
        }
    }, [address])
    return result;
}

export const verifyLink = process.env.REACT_APP_VERIFY_LINK! as string

export function VerifyAccountButtonLight() {
    return (
        <Link to={verifyLink} target="_blank" style={{textDecoration: "none"}}> 
            <ButtonLight onClick={() => {}} fontWeight={535} $borderRadius="16px">
                <Trans>Verify account</Trans>
            </ButtonLight>
        </Link>
    );
}

export function VerifyAccountButtonPrimary() {
    return (
        <Link to={verifyLink} target="_blank" style={{textDecoration: "none"}}> 
            <ButtonPrimary
            style={{ marginTop: '2em', marginBottom: '2em', padding: '8px 16px' }}
            onClick={() => {}}
            >
                <Trans>Verify account</Trans>
            </ ButtonPrimary>
        </Link>
    );
}

export function VerifyAccountButton() {
    return (
        <Web3StatusConnectWrapper
        tabIndex={0}
        onKeyPress={(e) => e.key === 'Enter'}
        onClick={() => {}}
         >
            <Link to={verifyLink} target="_blank"> 
                <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet" style={{padding: "20px"}}>
                    <Trans>Verify account</Trans>
                </StyledConnectButton>
            </Link>
        </Web3StatusConnectWrapper>
    );
}
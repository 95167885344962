import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { HavenIcon } from 'components/Logo/HavenIcon'
import Web3Status from 'components/Web3Status'
import { useInfoExplorePageEnabled } from 'featureFlags/flags/infoExplore'
import { useNewLandingPage } from 'featureFlags/flags/landingPageV2'
import { chainIdToBackendName } from 'graphql/data/util'
import { useDisableNFTRoutes } from 'hooks/useDisableNFTRoutes'
import { useIsLandingPage } from 'hooks/useIsLandingPage'
import { useIsNftPage } from 'hooks/useIsNftPage'
import { useIsPoolsPage } from 'hooks/useIsPoolsPage'
import { Box } from 'nft/components/Box'
import { Row } from 'nft/components/Flex'
import { useProfilePageState } from 'nft/hooks'
import { ProfilePageStateType } from 'nft/types'
import { GetTheAppButton } from 'pages/Landing/components/DownloadApp/GetTheAppButton'
import { ReactNode, useCallback } from 'react'
import { Link, NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { Chain } from 'graphql/data/__generated__/types-and-hooks'
import { Z_INDEX } from 'theme/zIndex'
import { useIsNavSearchInputVisible } from '../../nft/hooks/useIsNavSearchInputVisible'
import { Bag } from './Bag'
import Blur from './Blur'
import { ChainSelector } from './ChainSelector'
import { MenuDropdown } from './MenuDropdown'
import { More } from './More'
import { SearchBar } from './SearchBar'
import * as styles from './style.css'
import { vars } from 'nft/css/sprinkles.css'

const Nav = styled.nav`
  padding: ${({ theme }) => `${theme.navVerticalPad}px 12px`};
  width: 100%;
  height: ${({ theme }) => theme.navHeight}px;
  z-index: ${Z_INDEX.sticky};
`

interface MenuItemProps {
  href: string
  id?: NavLinkProps['id']
  isActive?: boolean
  children: ReactNode
  dataTestId?: string
  style: any
}

const MenuItem = ({ href, dataTestId, id, isActive, children, style }: MenuItemProps) => {
  return (
    <NavLink
      to={href}
      className={isActive ? styles.activeMenuItem : styles.menuItem}
      id={id}
      style={style}{...{ textDecoration: 'none' }}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  )
}

export const PageTabs = () => {
  const { pathname } = useLocation()
  const { chainId: connectedChainId } = useWeb3React()
  const chainName = chainIdToBackendName(connectedChainId)

  const isPoolActive = useIsPoolsPage()
  const isNftPage = useIsNftPage()

  const shouldDisableNFTRoutes = useDisableNFTRoutes()
  const infoExplorePageEnabled = useInfoExplorePageEnabled()
  const isNewLandingPageEnabled = useNewLandingPage()
  const isSwapActive = pathname.startsWith('/swap') || pathname.startsWith('/limit') || pathname == '/';

  return (
    <>
      <MenuItem style={{marginTop:"0", marginRight:"2px", borderRadius:"8px", fontWeight:"700", background: isSwapActive? vars.color.lightGrayOverlay : ""}} 
      href="/swap" isActive={isSwapActive}>
        <Trans>Swap</Trans>
      </MenuItem>
      <MenuItem style={{marginTop:"0", marginLeft:"2px", borderRadius:"8px", fontWeight:"700", background: isPoolActive? vars.color.lightGrayOverlay : ""}} 
      href="/pool" isActive={isPoolActive}>
        <Trans>Pool</Trans>
      </MenuItem>
    </>
  )
}

const Navbar = ({ blur }: { blur: boolean }) => {
  const isNftPage = useIsNftPage()
  const isLandingPage = useIsLandingPage()
  const isNewLandingPageEnabled = useNewLandingPage()
  const sellPageState = useProfilePageState((state) => state.state)
  const navigate = useNavigate()
  const isNavSearchInputVisible = useIsNavSearchInputVisible()

  const { account } = useWeb3React()
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleHavenIconClick = useCallback(() => {
    if (account) {
      return
    }
    if (accountDrawerOpen) {
      toggleAccountDrawer()
    }
    navigate({
      pathname: '/',
      search: '?intro=true',
    })
  }, [account, accountDrawerOpen, navigate, toggleAccountDrawer])

  return (
    <>
      {blur && <Blur />}
      <Nav>
        <Box display="flex" height="full" flexWrap="nowrap">
          <Box className={styles.leftSideContainer}>
            <Box className={styles.logoContainer}>
              <Link to="https://portal.testnet.haven1.org" target={"_blank"}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7071 4.29289C11.0976 4.68342 11.0976 5.31658 10.7071 5.70711L4.41421 12L10.7071 18.2929C11.0976 18.6834 11.0976 19.3166 10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.29289 19.7071L2.29289 12.7071C2.10536 12.5196 2 12.2652 2 12C2 11.7348 2.10536 11.4804 2.29289 11.2929L9.29289 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289Z" fill="#393A40"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L3 13C2.44772 13 2 12.5523 2 12Z" fill="#393A40"/>
                  </svg>
              </Link>
              <Link to="https://portal.testnet.haven1.org" target={"_blank"}>
                <HavenIcon clickable={true}/>
              </Link>
            </Box>
          </Box>
          <Box
            className={styles.searchContainer}
            {...{
              // display: 'flex'
            }}
          >
            <PageTabs />
          </Box>
          <Box className={styles.rightSideContainer}>
            <Row gap="12">
              <Web3Status />
            </Row>
          </Box>
        </Box>
      </Nav>
    </>
  )
}

export default Navbar

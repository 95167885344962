import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [NoTransactions, AnimatedNoTransactions] = createIcon({
  name: 'NoTransactions',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 114 96" {...props}>
      <Path
        clipRule="evenodd"
        d="M26.5247 10.001L11.2535 14.3799C7.53727 15.4455 5.38851 19.322 6.45413 23.0382L19.6601 69.093C20.7257 72.8092 24.6022 74.958 28.3184 73.8924L62.817 64H37.5247C31.4495 64 26.5247 59.0752 26.5247 53V10.001Z"
        fill={'currentColor' ?? '#CECECE'}
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M37.5244 1C33.6584 1 30.5244 4.13401 30.5244 8V53C30.5244 56.866 33.6584 60 37.5244 60H62.7608C66.618 52.6192 73.9151 47.3207 82.5244 46.2321V8C82.5244 4.13401 79.3904 1 75.5244 1H37.5244ZM43.0244 20C44.4051 20 45.5244 18.8807 45.5244 17.5C45.5244 16.1193 44.4051 15 43.0244 15C41.6437 15 40.5244 16.1193 40.5244 17.5C40.5244 18.8807 41.6437 20 43.0244 20ZM45.5244 30.5C45.5244 31.8807 44.4051 33 43.0244 33C41.6437 33 40.5244 31.8807 40.5244 30.5C40.5244 29.1193 41.6437 28 43.0244 28C44.4051 28 45.5244 29.1193 45.5244 30.5ZM43.0244 46C44.4051 46 45.5244 44.8807 45.5244 43.5C45.5244 42.1193 44.4051 41 43.0244 41C41.6437 41 40.5244 42.1193 40.5244 43.5C40.5244 44.8807 41.6437 46 43.0244 46ZM50.5244 17.5C50.5244 16.1193 51.6437 15 53.0244 15H70.0244C71.4051 15 72.5244 16.1193 72.5244 17.5C72.5244 18.8807 71.4051 20 70.0244 20H53.0244C51.6437 20 50.5244 18.8807 50.5244 17.5ZM53.0244 28C51.6437 28 50.5244 29.1193 50.5244 30.5C50.5244 31.8807 51.6437 33 53.0244 33H70.0244C71.4051 33 72.5244 31.8807 72.5244 30.5C72.5244 29.1193 71.4051 28 70.0244 28H53.0244ZM50.5244 43.5C50.5244 42.1193 51.6437 41 53.0244 41H70.0244C71.4051 41 72.5244 42.1193 72.5244 43.5C72.5244 44.8807 71.4051 46 70.0244 46H53.0244C51.6437 46 50.5244 44.8807 50.5244 43.5Z"
        fill={'currentColor' ?? '#CECECE'}
        fillRule="evenodd"
      />
      <Path
        clipRule="evenodd"
        d="M85.8188 94.0254C97.9691 94.0254 107.819 84.1757 107.819 72.0254C107.819 59.8751 97.9691 50.0254 85.8188 50.0254C73.6686 50.0254 63.8188 59.8751 63.8188 72.0254C63.8188 84.1757 73.6686 94.0254 85.8188 94.0254ZM94.0682 72.0251L85.8185 63.7754L77.5689 72.0251L85.8185 80.2748L94.0682 72.0251Z"
        fill={'currentColor' ?? '#CECECE'}
        fillRule="evenodd"
      />
    </Svg>
  ),
  defaultFill: '#CECECE',
})

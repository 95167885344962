import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [ChartPie, AnimatedChartPie] = createIcon({
  name: 'ChartPie',
  getIcon: (props) => (
    <Svg fill="currentColor" viewBox="0 0 16 16" {...props}>
      <Path
        d="M13.9626 7.21269C13.804 7.39536 13.574 7.50004 13.3333 7.50004H9.328C8.87134 7.50004 8.5 7.12875 8.5 6.67208V2.66671C8.5 2.42538 8.60467 2.19608 8.78801 2.03675C8.96867 1.88008 9.21133 1.80869 9.44133 1.84135C11.878 2.18202 13.8173 4.12139 14.1587 6.55872C14.1913 6.79339 14.12 7.03202 13.9626 7.21269ZM12.654 9.06873C12.5 8.88006 12.272 8.77137 12.0287 8.77137H8.06132C7.60199 8.77137 7.22799 8.39737 7.22799 7.93803V3.97075C7.22799 3.72808 7.11998 3.50001 6.93132 3.34534C6.74865 3.19601 6.51133 3.13668 6.28333 3.18201C4.84866 3.47068 3.55934 4.34068 2.74801 5.57068C1.92734 6.81402 1.64602 8.3194 1.95602 9.80807C2.39268 11.9041 4.09466 13.6054 6.19133 14.0427C6.58666 14.1254 6.98335 14.1661 7.37602 14.1661C8.46135 14.1661 9.51535 13.8547 10.428 13.2521C11.658 12.4401 12.528 11.1513 12.8167 9.71668C12.864 9.48801 12.8047 9.2514 12.654 9.06873Z"
        fill={'currentColor' ?? '#0151FE'}
      />
    </Svg>
  ),
  defaultFill: '#0151FE',
})

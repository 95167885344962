import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Feedback, AnimatedFeedback] = createIcon({
  name: 'Feedback',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M21 12.001C21 12.342 20.98 12.667 20.947 12.985C20.918 13.215 20.7 13.293 20.574 13.252C19.819 13.004 18.9911 12.932 18.1311 13.074C15.7591 13.465 13.993 15.616 14 18.02C14.002 18.514 14.073 18.988 14.209 19.439C14.261 19.613 14.1641 19.797 13.9851 19.83C13.3461 19.946 12.68 20.004 11.999 20.004C10.719 20.004 9.50894 19.804 8.40894 19.424C7.06894 20.854 4.87898 21.044 3.45898 20.994C3.02898 20.984 2.83895 20.4341 3.16895 20.1541C4.25895 19.2741 4.79897 18.174 4.94897 17.213C3.72897 15.893 2.99902 14.123 2.99902 12.002C2.99902 7.00095 7.02902 4.00098 11.999 4.00098C16.969 4.00098 21 6.99998 21 12.001ZM21 16.75H19.25V15C19.25 14.586 18.914 14.25 18.5 14.25C18.086 14.25 17.75 14.586 17.75 15V16.75H16C15.586 16.75 15.25 17.086 15.25 17.5C15.25 17.914 15.586 18.25 16 18.25H17.75V20C17.75 20.414 18.086 20.75 18.5 20.75C18.914 20.75 19.25 20.414 19.25 20V18.25H21C21.414 18.25 21.75 17.914 21.75 17.5C21.75 17.086 21.414 16.75 21 16.75Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

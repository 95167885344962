import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Edit, AnimatedEdit] = createIcon({
  name: 'Edit',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 19 19" {...props}>
      <Path
        d="M14 18.7497H4C1.582 18.7497 0.25 17.4177 0.25 14.9997V4.99973C0.25 2.58173 1.582 1.24973 4 1.24973H7C7.414 1.24973 7.75 1.58573 7.75 1.99973C7.75 2.41373 7.414 2.74973 7 2.74973H4C2.423 2.74973 1.75 3.42273 1.75 4.99973V14.9997C1.75 16.5767 2.423 17.2497 4 17.2497H14C15.577 17.2497 16.25 16.5767 16.25 14.9997V11.9997C16.25 11.5857 16.586 11.2497 17 11.2497C17.414 11.2497 17.75 11.5857 17.75 11.9997V14.9997C17.75 17.4177 16.418 18.7497 14 18.7497ZM18.58 2.02973L16.97 0.419745C16.4 -0.140255 15.49 -0.140276 14.92 0.429724L13.57 1.78974L17.21 5.42972L18.57 4.07972C19.14 3.50972 19.14 2.59973 18.58 2.02973ZM12.51 2.84974L6 9.38975V12.9997H9.60999L16.15 6.48972L12.51 2.84974Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Photo, AnimatedPhoto] = createIcon({
  name: 'Photo',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 24 25" {...props}>
      <Path
        d="M22.448 7.89973L21.248 16.4808C21.091 17.5738 20.664 18.3498 19.991 18.7928C19.758 18.9128 19.5031 18.7919 19.5031 18.5489V9.95783C19.5031 7.34883 17.9451 5.79084 15.3361 5.79084C15.3361 5.79084 6.22395 5.79084 5.92395 5.79084C5.62395 5.79084 5.61694 5.5207 5.63794 5.4007C5.79594 4.4547 6.17 3.7547 6.75 3.3177C7.357 2.8607 8.17702 2.7018 9.19202 2.8448L19.7531 4.32478C20.7701 4.46878 21.518 4.84983 21.976 5.45783C22.433 6.06483 22.592 6.88673 22.448 7.89973ZM18.0031 9.95686V18.6248C18.0031 20.4028 17.1141 21.2918 15.3361 21.2918H4.66797C2.88997 21.2918 2.00098 20.4028 2.00098 18.6248V9.95686C2.00098 8.17886 2.88997 7.28987 4.66797 7.28987H15.3361C17.1141 7.28987 18.0031 8.17786 18.0031 9.95686ZM3.5 9.95686V17.1348L4.93298 15.7017C5.27298 15.3517 5.84299 15.3517 6.19299 15.7017L7.02295 16.5318C7.19295 16.7018 7.48295 16.7018 7.65295 16.5318L12.043 12.1419C12.383 11.8019 12.953 11.8019 13.303 12.1419L16.5031 15.3499V9.95686C16.5031 9.00786 16.2851 8.78987 15.3361 8.78987H4.66797C3.71797 8.78987 3.5 9.00786 3.5 9.95686ZM6.50195 12.7918C7.05295 12.7918 7.5 12.3438 7.5 11.7918C7.5 11.2398 7.05295 10.7918 6.50195 10.7918H6.49402C5.94302 10.7918 5.5 11.2398 5.5 11.7918C5.5 12.3438 5.95095 12.7918 6.50195 12.7918Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [ScanHome, AnimatedScanHome] = createIcon({
  name: 'ScanHome',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 29 28" {...props}>
      <Path
        clipRule="evenodd"
        d="M3.56925 2.94428C4.32954 2.18398 5.36072 1.75684 6.43595 1.75684H9.89212C10.8586 1.75684 11.6421 2.54034 11.6421 3.50684C11.6421 4.47333 10.8586 5.25684 9.89212 5.25684H6.43595C6.289 5.25684 6.14806 5.31521 6.04414 5.41913C5.94022 5.52306 5.88184 5.66401 5.88184 5.81099V9.26723C5.88184 10.2337 5.09833 11.0172 4.13184 11.0172C3.16534 11.0172 2.38184 10.2337 2.38184 9.26723V5.81099C2.38184 4.73577 2.80896 3.70459 3.56925 2.94428ZM17.3586 3.50684C17.3586 2.54034 18.1421 1.75684 19.1086 1.75684H22.5648C23.64 1.75684 24.6712 2.18398 25.4315 2.94428C26.1917 3.70459 26.6189 4.73577 26.6189 5.81099V9.26723C26.6189 10.2337 25.8354 11.0172 24.8689 11.0172C23.9024 11.0172 23.1189 10.2337 23.1189 9.26723V5.81099C23.1189 5.66401 23.0605 5.52306 22.9566 5.41913C22.8526 5.31521 22.7117 5.25684 22.5648 5.25684H19.1086C18.1421 5.25684 17.3586 4.47333 17.3586 3.50684ZM4.13184 16.7339C5.09833 16.7339 5.88184 17.5174 5.88184 18.4839V21.9401C5.88184 22.0871 5.94022 22.228 6.04414 22.3319C6.14806 22.4359 6.289 22.4942 6.43595 22.4942H9.89212C10.8586 22.4942 11.6421 23.2777 11.6421 24.2442C11.6421 25.2107 10.8586 25.9942 9.89212 25.9942H6.43595C5.36072 25.9942 4.32954 25.5671 3.56925 24.8068C2.80896 24.0465 2.38184 23.0153 2.38184 21.9401V18.4839C2.38184 17.5174 3.16534 16.7339 4.13184 16.7339ZM24.8689 16.7339C25.8354 16.7339 26.6189 17.5174 26.6189 18.4839V21.9401C26.6189 23.0153 26.1917 24.0465 25.4315 24.8068C24.6712 25.5671 23.64 25.9942 22.5648 25.9942H19.1086C18.1421 25.9942 17.3586 25.2107 17.3586 24.2442C17.3586 23.2777 18.1421 22.4942 19.1086 22.4942H22.5648C22.7117 22.4942 22.8526 22.4359 22.9566 22.3319C23.0605 22.228 23.1189 22.0871 23.1189 21.9401V18.4839C23.1189 17.5174 23.9024 16.7339 24.8689 16.7339Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  ),
})

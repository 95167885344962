import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [TimePast, AnimatedTimePast] = createIcon({
  name: 'TimePast',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 13 14" {...props}>
      <Path
        d="M6.66643 4.33325V6.72388L7.80445 7.8619C8.06512 8.12256 8.06512 8.54394 7.80445 8.80461C7.67445 8.93461 7.50376 8.99992 7.3331 8.99992C7.16243 8.99992 6.99174 8.93461 6.86174 8.80461L5.52841 7.47127C5.40307 7.34594 5.3331 7.17659 5.3331 6.99992V4.33325C5.3331 3.96525 5.6311 3.66659 5.99976 3.66659C6.36843 3.66659 6.66643 3.96525 6.66643 4.33325ZM6.01701 0.333252C4.23701 0.333252 2.56576 1.05187 1.33309 2.28654L1.33309 0.999919C1.33309 0.631919 1.03509 0.333252 0.666428 0.333252C0.297761 0.333252 -0.000238553 0.631919 -0.000238553 0.999919L-0.000238553 3.66659C-0.000238553 4.03459 0.297761 4.33325 0.666428 4.33325L3.3331 4.33325C3.70176 4.33325 3.99976 4.03459 3.99976 3.66659C3.99976 3.29859 3.70176 2.99992 3.3331 2.99992H2.50709C3.46575 2.15125 4.70635 1.66659 6.01701 1.66659C8.94768 1.66659 11.3331 4.05925 11.3331 6.99992C11.3331 9.94059 8.94835 12.3333 6.01701 12.3333C3.98035 12.3333 2.15974 11.1993 1.26441 9.37329C1.10308 9.04262 0.703816 8.90529 0.372483 9.06795C0.0418162 9.22995 -0.0942047 9.62921 0.0677953 9.95988C1.18846 12.2465 3.46835 13.6666 6.01701 13.6666C9.68301 13.6666 12.6664 10.6759 12.6664 6.99992C12.6664 3.32392 9.68301 0.333252 6.01701 0.333252Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [TrashFilled, AnimatedTrashFilled] = createIcon({
  name: 'TrashFilled',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 24 24" {...props}>
      <Path
        d="M8.214 5.75H8.52001L8.65924 5.47751C8.77151 5.25781 8.85397 5.01821 8.93013 4.79074L8.93041 4.78989L9.13235 4.18311C9.26824 3.77544 9.65004 3.5 10.081 3.5H13.919C14.35 3.5 14.7318 3.77544 14.8677 4.18311L15.0696 4.78989L15.0699 4.79074C15.146 5.01821 15.2285 5.25781 15.3408 5.47751L15.48 5.75H15.786H20C20.1379 5.75 20.25 5.86214 20.25 6C20.25 6.13786 20.1379 6.25 20 6.25H4C3.86214 6.25 3.75 6.13786 3.75 6C3.75 5.86214 3.86214 5.75 4 5.75H8.214ZM5.65398 8.25H18.346L17.6912 18.1653C17.6912 18.1655 17.6912 18.1658 17.6912 18.166C17.641 18.8842 17.4313 19.4571 17.0592 19.8476C16.696 20.2289 16.1172 20.5 15.19 20.5H8.81C7.88279 20.5 7.30399 20.2289 6.94075 19.8476C6.56874 19.4571 6.35899 18.8843 6.30885 18.1662C6.30883 18.1659 6.30881 18.1656 6.30879 18.1653L5.65398 8.25ZM11.25 11C11.25 10.3139 10.6861 9.75 10 9.75C9.31386 9.75 8.75 10.3139 8.75 11V16C8.75 16.6861 9.31386 17.25 10 17.25C10.6861 17.25 11.25 16.6861 11.25 16V11ZM15.25 11C15.25 10.3139 14.6861 9.75 14 9.75C13.3139 9.75 12.75 10.3139 12.75 11V16C12.75 16.6861 13.3139 17.25 14 17.25C14.6861 17.25 15.25 16.6861 15.25 16V11Z"
        fill={'currentColor' ?? '#FF5F52'}
        stroke="currentColor"
      />
    </Svg>
  ),
  defaultFill: '#FF5F52',
})

import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [XTwitter, AnimatedXTwitter] = createIcon({
  name: 'XTwitter',
  getIcon: (props) => (
    <Svg fill="currentColor" viewBox="0 0 512 512" {...props}>
      <Path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
    </Svg>
  ),
})

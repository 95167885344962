import { G, Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [LinkBrokenHorizontal, AnimatedLinkBrokenHorizontal] = createIcon({
  name: 'LinkBrokenHorizontal',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 25 24" {...props}>
      <G id="Icon">
        <Path
          d="M16.5 18.0002H14.5C13.947 18.0002 13.5 17.5523 13.5 17.0003C13.5 16.4483 13.947 16.0003 14.5 16.0003H16.5C18.706 16.0003 20.5 14.2063 20.5 12.0003C20.5 9.79425 18.706 8.00025 16.5 8.00025H14.5C13.947 8.00025 13.5 7.55225 13.5 7.00025C13.5 6.44825 13.947 6.00025 14.5 6.00025H16.5C19.809 6.00025 22.5 8.69225 22.5 12.0003C22.5 15.3083 19.809 18.0002 16.5 18.0002ZM11.5 17.0003C11.5 16.4483 11.053 16.0003 10.5 16.0003H8.5C6.294 16.0003 4.5 14.2063 4.5 12.0003C4.5 9.79425 6.294 8.00025 8.5 8.00025H10.5C11.053 8.00025 11.5 7.55225 11.5 7.00025C11.5 6.44825 11.053 6.00025 10.5 6.00025H8.5C5.191 6.00025 2.5 8.69225 2.5 12.0003C2.5 15.3083 5.191 18.0002 8.5 18.0002H10.5C11.053 18.0002 11.5 17.5523 11.5 17.0003ZM11.707 4.70725C12.098 4.31725 12.098 3.68325 11.707 3.29325L10.707 2.29325C10.316 1.90225 9.68397 1.90225 9.29297 2.29325C8.90197 2.68325 8.90197 3.31725 9.29297 3.70725L10.293 4.70725C10.488 4.90225 10.744 5.00025 11 5.00025C11.256 5.00025 11.512 4.90225 11.707 4.70725ZM14.707 4.70725L15.707 3.70725C16.098 3.31725 16.098 2.68325 15.707 2.29325C15.316 1.90225 14.684 1.90225 14.293 2.29325L13.293 3.29325C12.902 3.68325 12.902 4.31725 13.293 4.70725C13.488 4.90225 13.744 5.00025 14 5.00025C14.256 5.00025 14.512 4.90225 14.707 4.70725ZM15.707 21.7073C16.098 21.3173 16.098 20.6832 15.707 20.2932L14.707 19.2932C14.316 18.9022 13.684 18.9022 13.293 19.2932C12.902 19.6832 12.902 20.3173 13.293 20.7073L14.293 21.7073C14.488 21.9023 14.744 22.0002 15 22.0002C15.256 22.0002 15.512 21.9023 15.707 21.7073ZM10.707 21.7073L11.707 20.7073C12.098 20.3173 12.098 19.6832 11.707 19.2932C11.316 18.9022 10.684 18.9022 10.293 19.2932L9.29297 20.2932C8.90197 20.6832 8.90197 21.3173 9.29297 21.7073C9.48797 21.9023 9.744 22.0002 10 22.0002C10.256 22.0002 10.512 21.9023 10.707 21.7073Z"
          fill={'currentColor' ?? '#7D7D7D'}
          id="link-broken-horizontal"
        />
      </G>
    </Svg>
  ),
  defaultFill: '#7D7D7D',
})

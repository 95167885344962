import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [MoneyBillSend, AnimatedMoneyBillSend] = createIcon({
  name: 'MoneyBillSend',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 20 17" {...props}>
      <Path
        d="M15.5 0H3.5C1.5 0 0.5 1 0.5 3V11C0.5 13 1.5 14 3.5 14H11.24C11.41 14 11.55 13.86 11.55 13.69C11.55 13.65 11.54 13.61 11.53 13.57C11.51 13.38 11.5 13.19 11.5 13C11.5 10.24 13.74 8 16.5 8C17.06 8 17.6 8.09001 18.1 8.26001C18.3 8.32001 18.5 8.18998 18.5 7.97998V3C18.5 1 17.5 0 15.5 0ZM3.5 7.99902C2.948 7.99902 2.5 7.55102 2.5 6.99902C2.5 6.44702 2.948 5.99902 3.5 5.99902C4.052 5.99902 4.5 6.44702 4.5 6.99902C4.5 7.55202 4.052 7.99902 3.5 7.99902ZM9.5 10C7.843 10 6.5 8.657 6.5 7C6.5 5.343 7.843 4 9.5 4C11.157 4 12.5 5.343 12.5 7C12.5 8.657 11.157 10 9.5 10ZM19.03 13.53C18.884 13.676 18.692 13.75 18.5 13.75C18.308 13.75 18.116 13.677 17.97 13.53L17.25 12.81V16C17.25 16.414 16.914 16.75 16.5 16.75C16.086 16.75 15.75 16.414 15.75 16V12.811L15.03 13.531C14.737 13.824 14.262 13.824 13.969 13.531C13.676 13.238 13.676 12.763 13.969 12.47L15.969 10.47C16.262 10.177 16.737 10.177 17.03 10.47L19.03 12.47C19.323 12.762 19.323 13.238 19.03 13.53Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [ReceiveDots, AnimatedReceiveDots] = createIcon({
  name: 'ReceiveDots',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 25 25" {...props}>
      <Path
        d="M4.09494 7.18749C4.11269 5.39316 5.26472 4.06001 7.05844 4.01031C7.2977 4.00368 7.55585 4 7.83416 4C8.11246 4 8.37061 4.00368 8.60987 4.01031C10.4036 4.06001 11.5556 5.39316 11.5734 7.18749C11.5751 7.36199 11.576 7.54661 11.576 7.74187C11.576 7.93713 11.5751 8.12175 11.5734 8.29626C11.5556 10.0906 10.4036 11.4237 8.60987 11.4734C8.37061 11.4801 8.11246 11.4837 7.83416 11.4837C7.55585 11.4837 7.2977 11.4801 7.05844 11.4734C5.26472 11.4237 4.11269 10.0906 4.09494 8.29626C4.09322 8.12175 4.09229 7.93713 4.09229 7.74187C4.09229 7.54661 4.09322 7.36199 4.09494 7.18749Z"
        fill="currentColor"
      />
      <Path
        d="M4.09494 17.443C4.11269 19.2373 5.26472 20.5705 7.05844 20.6202C7.2977 20.6268 7.55585 20.6305 7.83416 20.6305C8.11246 20.6305 8.37061 20.6268 8.60987 20.6202C10.4036 20.5705 11.5556 19.2373 11.5734 17.443C11.5751 17.2685 11.576 17.0839 11.576 16.8886C11.576 16.6934 11.5751 16.5087 11.5734 16.3342C11.5556 14.5399 10.4036 13.2068 8.60987 13.1571C8.37061 13.1504 8.11246 13.1467 7.83416 13.1467C7.55585 13.1467 7.2977 13.1504 7.05844 13.1571C5.26472 13.2068 4.11269 14.5399 4.09494 16.3342C4.09322 16.5087 4.09229 16.6934 4.09229 16.8886C4.09229 17.0839 4.09322 17.2685 4.09494 17.443Z"
        fill="currentColor"
      />
      <Path
        d="M17.5352 4.00266C19.3295 4.0204 20.6626 5.17243 20.7123 6.96616C20.719 7.20542 20.7227 7.46357 20.7227 7.74187C20.7227 8.02018 20.719 8.27833 20.7123 8.51759C20.6626 10.3113 19.3295 11.4633 17.5352 11.4811C17.3607 11.4828 17.176 11.4837 16.9808 11.4837C16.7855 11.4837 16.6009 11.4828 16.4264 11.4811C14.6321 11.4633 13.2989 10.3113 13.2492 8.51759C13.2426 8.27833 13.2389 8.02017 13.2389 7.74187C13.2389 7.46357 13.2426 7.20542 13.2492 6.96616C13.2989 5.17243 14.6321 4.0204 16.4264 4.00266C16.6009 4.00093 16.7855 4 16.9808 4C17.176 4 17.3607 4.00093 17.5352 4.00266Z"
        fill="currentColor"
      />
      <Path
        d="M17.5352 20.6278C19.3295 20.6101 20.6626 19.4581 20.7123 17.6643C20.719 17.4251 20.7227 17.1669 20.7227 16.8886C20.7227 16.6103 20.719 16.3522 20.7123 16.1129C20.6626 14.3192 19.3295 13.1672 17.5352 13.1494C17.3607 13.1477 17.176 13.1467 16.9808 13.1467C16.7855 13.1467 16.6009 13.1477 16.4264 13.1494C14.6321 13.1672 13.2989 14.3192 13.2492 16.1129C13.2426 16.3522 13.2389 16.6103 13.2389 16.8886C13.2389 17.1669 13.2426 17.4251 13.2492 17.6643C13.2989 19.4581 14.6321 20.6101 16.4264 20.6278C16.6009 20.6296 16.7855 20.6305 16.9808 20.6305C17.176 20.6305 17.3607 20.6296 17.5352 20.6278Z"
        fill="currentColor"
      />
    </Svg>
  ),
})

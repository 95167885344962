import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [Coin, AnimatedCoin] = createIcon({
  name: 'Coin',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 19 14" {...props}>
      <Path
        d="M0.5 7.67999C1.27 8.41199 2.31102 9.03502 3.55402 9.49902C3.67002 9.54202 3.75 9.652 3.75 9.776V12.693C3.75 12.907 3.52699 13.058 3.33099 12.973C1.58499 12.222 0.5 11.059 0.5 9.5V7.67999ZM8.75 10.763V13.665C8.75 13.836 8.60398 13.976 8.43298 13.969C7.38298 13.928 6.387 13.81 5.487 13.621C5.347 13.592 5.25 13.466 5.25 13.323V10.39C5.25 10.199 5.42097 10.061 5.60797 10.101C6.49697 10.294 7.45699 10.42 8.46399 10.467C8.62299 10.476 8.75 10.604 8.75 10.763ZM13.75 10.391V13.324C13.75 13.467 13.654 13.593 13.513 13.622C12.612 13.811 11.616 13.929 10.567 13.97C10.396 13.977 10.25 13.837 10.25 13.666V10.764C10.25 10.605 10.377 10.477 10.536 10.469C11.544 10.422 12.503 10.296 13.392 10.103C13.579 10.062 13.75 10.2 13.75 10.391ZM18.5 7.67999V9.5C18.5 11.059 17.415 12.222 15.669 12.973C15.473 13.057 15.25 12.906 15.25 12.693V9.776C15.25 9.652 15.33 9.54202 15.446 9.49902C16.689 9.03502 17.73 8.41199 18.5 7.67999ZM0.5 4.5C0.5 6.985 4.529 9 9.5 9C14.471 9 18.5 6.985 18.5 4.5C18.5 2.015 14.471 0 9.5 0C4.529 0 0.5 2.015 0.5 4.5Z"
        fill={'currentColor' ?? '#7D7D7D'}
      />
    </Svg>
  ),
  defaultFill: '#7D7D7D',
})

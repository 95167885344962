import { Path, Svg } from 'react-native-svg'

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import { createIcon } from '../factories/createIcon'

export const [PlusCircle, AnimatedPlusCircle] = createIcon({
  name: 'PlusCircle',
  getIcon: (props) => (
    <Svg fill="none" viewBox="0 0 29 28" {...props}>
      <Path
        d="M2.9585 13.9997C2.9585 7.69775 8.07323 2.58301 14.3752 2.58301C20.6771 2.58301 25.7918 7.69775 25.7918 13.9997C25.7918 20.3016 20.6771 25.4163 14.3752 25.4163C8.07323 25.4163 2.9585 20.3016 2.9585 13.9997ZM16.7502 15.1247H18.4585C19.0749 15.1247 19.5835 14.6161 19.5835 13.9997C19.5835 13.3833 19.0749 12.8747 18.4585 12.8747H16.7502C16.0598 12.8747 15.5002 12.315 15.5002 11.6247V9.91634C15.5002 9.29994 14.9916 8.79134 14.3752 8.79134C13.7588 8.79134 13.2502 9.29994 13.2502 9.91634V11.6247C13.2502 12.315 12.6905 12.8747 12.0002 12.8747H10.2918C9.67542 12.8747 9.16683 13.3833 9.16683 13.9997C9.16683 14.6161 9.67542 15.1247 10.2918 15.1247H12.0002C12.6905 15.1247 13.2502 15.6843 13.2502 16.3747V18.083C13.2502 18.6994 13.7588 19.208 14.3752 19.208C14.9916 19.208 15.5002 18.6994 15.5002 18.083V16.3747C15.5002 15.6843 16.0598 15.1247 16.7502 15.1247Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </Svg>
  ),
})
